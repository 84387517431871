@import url('https://fonts.googleapis.com/css?family=Spartan&display=swap');

// you can import third-party (S)CSS from node module packages
// for example to include bulma and make all its classes available,
// simply uncomment the following class name
// @import "bulma";

* {
    //font-family: 'Spartan', sans-serif;
}

body {
    padding: 0;
    margin: 0;
}



/* Overrides for react-big-calendar */

.rbc-event {
    padding: 10px;
}

.rbc-event-label {
    margin-bottom: 5px;
}

.rbc-timeslot-group {
    padding-top: 12px;
}

.rbc-header {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rbc-button-link {
    display: block;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: #1a73e8;
    border-color: #1a73e8;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
    border-color: #1a73e8;
}

.placedescription-slot {
    background-color: #00ff90;
}

.rbc-event.place-descr-event {
    background-color: #f9df95; //#d2dfef;
    color: black;
    border-color: #97a7bb !important;
}
